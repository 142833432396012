export enum RequestTypeEnum {
  //permissions
  PhotoCapture = 1,
  OneTimeInputOutput = 2,
  VisitorEntry = 4,
  TemporaryEntry = 5,
  MultipleInputOutput = 6,
  RenewTemporaryEntry = 7,
  DeviceWithCameraInput = 8,
  ReviewOneTimeInputOutput = 9,
  MaintenanceRequest = 10,
  NewLocation = 11,
  //reports
  EntryExitReport = 20,
  LightingSecurityFencesReport = 21,
  DailySecurityReport = 22,
  SecurityScanReport = 23,
  FuelTrucksReport = 24,
  LocationSecurityDataReport = 25,
  SecurityHandoverReport = 26,
  SecurityProjectsReport = 27,
  InspectionVisitReport = 28,
  SecurityNotesReport = 43,
  SecurityNote = 44,
  //incidents
  RobberyIncident = 30,
  AssaultIncident = 31,
  NoteOrSuspicionIncident = 32,
  InitiationAttachment = 45,
  //ShiftManagement
  AddingShiftRecord = 40,
  CancellingShiftRecord = 41,
  UpdatingShiftsForSpecificDay = 42,
  // Feedback
  Feedback = 'Feedback',
  // Weapons
  GateArmingRequestCommand = 'GateArmingRequestCommand',
  WeaponForm = 'WeaponForm',
  MenRequest = 'SecurityManArmingRequest',
  SafeHandoverSession = 'SafeHandoverSession',
}
